import {
  BUYING_CATEGORY,
  CH_BUYING_FEATURE,
  CH_HOME_FEATURE,
  CH_RENTING_FEATURE,
  CH_SELLING_FEATURE,
  CH_STUDENTS_FEATURE,
  DEFAULT_META_DESCRIPTION,
  NEWS_CATEGORIES,
  NEWS_FEATURE,
  RENTING_CATEGORY,
  SELLING_CATEGORY,
  STUDENTS_CATEGORY,
} from '@propertypal/shared/src/constants/content-hub';
import axios, { STRAPI_URL } from '@propertypal/shared/src/services/axios.strapi';
import { StrapiResponse } from '@propertypal/shared/src/types/strapi/generic';
import {
  ContentHubArticle,
  ContentHubArticleCategory,
  ContentHubSubCategory,
} from '@propertypal/shared/src/types/strapi/overrides';
import { getArticleCategoryParams } from '@propertypal/shared/src/utils/content-hub';
import getArticleCategory from '@propertypal/shared/src/utils/getArticleCategory';
import ContentHubBreadcrumbs from '@propertypal/web-ui/src/content-hub/ContentHubBreadcrumbs';
import ContentHubCarousel from '@propertypal/web-ui/src/content-hub/ContentHubCarousel';
import ContentHubGrid from '@propertypal/web-ui/src/content-hub/ContentHubGrid';
import ContentHubHeaderCarousel from '@propertypal/web-ui/src/content-hub/ContentHubHeaderCarousel';
import { ContentHubContent } from '@propertypal/web-ui/src/content-hub/ContentHubPageContainer.style';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import React from 'react';
import ContentHubLayout from '../../components/layout/ContentHubLayout';
import generateMetaTags from '../../components/layout/MetaTags';
import cachedRequest from '../../services/cachedRequest.strapi';

interface Props {
  subCategories: ContentHubSubCategory[];
  categories: ContentHubSubCategory[] | null;
  categoryName: string;
  category: ContentHubArticleCategory;
  trending: ContentHubArticle[] | null;
}

export const getServerSideProps: GetServerSideProps = async ({ query }) => {
  const { params } = getArticleCategoryParams();
  // get the category enum
  const category = query.category as ContentHubArticleCategory;
  const result = await cachedRequest('/article-sub-categories', 6, params);

  // prevent rendering any data if feature flag is not set for category
  if (
    (!CH_BUYING_FEATURE && category === BUYING_CATEGORY) ||
    (!CH_SELLING_FEATURE && category === SELLING_CATEGORY) ||
    (!CH_RENTING_FEATURE && category === RENTING_CATEGORY) ||
    (!CH_STUDENTS_FEATURE && category === STUDENTS_CATEGORY) ||
    (!NEWS_FEATURE && NEWS_CATEGORIES.includes(category))
  ) {
    return {
      redirect: {
        permanent: false,
        destination: '/',
      },
    };
  }

  const categories = await axios<StrapiResponse<ContentHubSubCategory[]>>({
    method: 'GET',
    url: `${STRAPI_URL}/article-sub-categories`,
    params: {
      populate: 'deep,3',
      'filters[articleCategory][$eq]': category,
      // sort via publication date, so that the most recent appear first (descending)
      'sort[0]': 'publishedAt:desc',
    },
  });

  const trending = await axios<StrapiResponse<ContentHubArticle[]>>({
    method: 'GET',
    url: `${STRAPI_URL}/articles`,
    params: {
      populate: 'deep,3',
      'filters[trending]': 'true',
      'filters[subCategories][articleCategory][$eq]': category,
      // sort via publication date, so that the most recent appear first (descending)
      'sort[0]': 'publishedAt:desc',
      'pagination[page]': 1,
      // limit to 3 items
      'pagination[pageSize]': 3,
    },
  });

  return {
    props: {
      categories: categories.data?.data,
      categoryName: getArticleCategory(category),
      category,
      trending: trending.data?.data,
      subCategories: result.data,
      disableHeader: true,
      disableFooter: true,
    },
  };
};

const ContentHubCategory: React.FC<Props> = ({ subCategories, categories, categoryName, category, trending }) => {
  const illustrationIndex = categories?.findIndex((c) => c.attributes.landingPageType === 'grid');
  return (
    <>
      <Head>
        {generateMetaTags({
          title: `${categoryName} - PropertyPal`,
          url: `/${category}`,
          description: DEFAULT_META_DESCRIPTION,
        })}
      </Head>
      <ContentHubLayout subCategories={subCategories}>
        {trending && trending.length > 0 && <ContentHubHeaderCarousel trending={trending} />}

        {CH_HOME_FEATURE && (
          <ContentHubContent mt={18}>
            <ContentHubBreadcrumbs
              breadcrumbs={[{ title: 'Home', url: '/content-hub' }, { title: categoryName }]}
              mb={0}
            />
          </ContentHubContent>
        )}

        {categories?.map((c, i) => {
          if (c.attributes.landingPageType === 'carousel')
            return (
              <ContentHubCarousel
                key={c.id}
                articles={c.attributes.articles?.data}
                title={c.attributes.subCategory!}
                viewMore={`/${c.attributes.articleCategory}/${c.attributes.slug}`}
              />
            );
          return (
            <ContentHubGrid
              key={c.id}
              articles={c.attributes.articles?.data}
              title={c.attributes.subCategory!}
              viewMore={`/${c.attributes.articleCategory}/${c.attributes.slug}`}
              illustration={i === illustrationIndex}
            />
          );
        })}
      </ContentHubLayout>
    </>
  );
};

export default ContentHubCategory;
